import { styled } from "styled-components";
import theme from "styled-theming";

import { default as NavLink } from "components/Link";
import { Container as GridContainer } from "style/components/Page";
import { styleBodyM, styleHeadingXL } from "style/components/Typography";
import { columnGap } from "style/grid";
import { topPadOffset } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    ${columnGap};
    display: grid;
    flex-flow: column;
    grid-template-columns: repeat(4, [col-start] 1fr);
    justify-content: flex-start;
    position: relative;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const Heading = styled.h2.attrs<{
    $headerVisible: boolean;
}>(() => ({}))`
    ${styleHeadingXL};
    grid-column: ${theme("displayOption", {
            Left: "1",
            Right: "2",
        })} / span 3;
    justify-self: ${theme("displayOption", {
        Left: "flex-start",
        Right: "flex-end",
    })};
    margin: 0;
    text-align: ${theme("displayOption", {
        Left: "left",
        Right: "right",
    })};
    z-index: 2;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
                Left: "1",
                Right: "5",
            })} / span 8;
    }

    ${MQ.FROM_L} {
        align-self: start;
        grid-column: ${theme("displayOption", {
                Left: "1",
                Right: "9",
            })} / span 4;
        position: sticky;
        top: 4.375rem;
        ${topPadOffset};
    }

    ${MQ.FROM_XL} {
        grid-column: ${theme("displayOption", {
                Left: "1",
                Right: "7",
            })} / span 6;
    }
`;

export const CallToActions = styled.div`
    ${columnGap};
    display: grid;
    grid-column: 1 / span 4;
    grid-template-columns: repeat(4, 1fr);
    justify-content: flex-start;
    margin-top: 2.1875rem;
    row-gap: 2.625rem;

    ${MQ.FROM_M} {
        grid-column: 1 / span 12;
        row-gap: 3.9375rem;
    }

    ${MQ.FROM_L} {
        grid-column: ${theme("displayOption", {
                Left: "5",
                Right: "1",
            })} / span 8;
        grid-row: 1;
        margin-top: 3.9375rem;
    }

    ${MQ.FROM_XL} {
        grid-column: ${theme("displayOption", {
                Left: "7",
                Right: "1",
            })} / span 6;
        grid-row: 1;
        margin-top: 7rem;
        row-gap: 5.25rem;
    }
`;

export const CallToActionContainer = styled.div`
    grid-column: auto / span 4;

    ${MQ.FROM_M} {
        grid-column: auto / span 2;
    }
`;
export const borderColor = theme("contentTheme", {
    black: "var(--color-black-o30)",
    cyan: "var(--color-cyan-l60-o30)",
    blue: "var(--color-blue-o30)",
    coral: "var(--color-coral-o30)",
    image: "var(--color-white-o30)",
});

export const Link = styled(NavLink)`
    ${styleBodyM}
    display: block;
    grid-column: 1 / span 4;
    margin-top: 0.875rem;
    width: 100%;

    span {
        border-bottom-color: ${borderColor};
    }

    &:hover {
        span {
            border-bottom-color: currentcolor;
        }
    }

    svg {
        margin-left: 0.875rem;
    }
`;
