import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import CallToAction from "components/CallToAction";
import Icon from "components/Icon";
import Section from "components/Section";
import { useHeaderVisible } from "hooks";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    Heading,
    CallToActions,
    CallToActionContainer,
    Link,
} from "./ProductCategoryList.styled";
import ProductCategoryListProps from "./ProductCategoryListProps";

const ProductCategoryList = ({
    identifier,
    theme = "blue",
    contentTheme = "cyan",
    heading,
    displayOption = "Left",
    items,
    link,
    anchorName,
}: ProductCategoryListProps): ReactElement => {
    const headerVisible = useHeaderVisible();
    return (
        <Section theme={theme} id={anchorName} alignItems="center">
            <ThemeProvider
                theme={{
                    theme: theme,
                    contentTheme: contentTheme,
                    displayOption: displayOption,
                }}
            >
                <Container key={identifier || `ProductCategoryListContent`}>
                    {heading && (
                        <Heading
                            as="h2"
                            {...addEditAttributes("Heading")}
                            $headerVisible={headerVisible}
                            dangerouslySetInnerHTML={{ __html: heading }}
                        />
                    )}
                    <CallToActions {...addEditAttributes("ProductCategories")}>
                        {items?.map((item, index) => {
                            return (
                                <CallToActionContainer
                                    key={`CallToAction${index}`}
                                >
                                    <CallToAction
                                        contentTheme={contentTheme || "default"}
                                        {...item}
                                    />
                                </CallToActionContainer>
                            );
                        })}
                        {link?.linkText && (
                            <Link linkRef={link} hoverSpan={true}>
                                <span>{link.linkText}</span>
                                <Icon
                                    icon="longArrowRight28"
                                    aria-hidden="true"
                                />
                            </Link>
                        )}
                    </CallToActions>
                </Container>
            </ThemeProvider>
        </Section>
    );
};

export default React.memo(ProductCategoryList);
